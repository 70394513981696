@media (orientation: landscape) and (max-width: 639px), (orientation: portrait) and (max-height: 479px) {
	#header {
		background-image: url("/img/raster/hiking-400.jpg");
	}
}

@media (orientation: landscape) and (min-width: 640px) and (max-width: 799px), (orientation: portrait) and (min-height: 480px) and (max-height: 599px) {
	#header {
		background-image: url("/img/raster/hiking-640.jpg");
	}
}

@media (orientation: landscape) and (min-width: 800px) and (max-width: 1279px), (orientation: portrait) and (min-height: 600px) and (max-height: 959px) {
	#header {
		background-image: url("/img/raster/hiking-800.jpg");
	}
}

@media (orientation: landscape) and (min-width: 1280px) and (max-width: 1439px), (orientation: portrait) and (min-height: 960px) and (max-height: 1079px) {
	#header {
		background-image: url("/img/raster/hiking-1280.jpg");
	}
}

@media (min-width: 1440px), (min-height: 1080px) {
	#header {
		background-image: url("/img/raster/hiking.jpg");
	}
}
